import { DailyEvents } from "models/Event";
import { DailyEventsCard } from "./DailyEventsCard";

export const DailyEventsCardList = ({
  dailyEvents,
}: {
  dailyEvents: DailyEvents[] | undefined;
}) => {
  const keyGen = (e: DailyEvents) => `daily-event-${e.date}`;
  const eventsCards = dailyEvents?.map((des) => (
    <DailyEventsCard key={keyGen(des)} dailyEvents={des} />
  ));
  return <div className="border-transparent order-r-8">{eventsCards}</div>;
};
