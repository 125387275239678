import { NavBar } from "./NavBar";
import NavLogo from "./NavLogo";
import NavTitle from "./NavTitle";
import NavNewEvent from "./NavNewEvent";
import NavLogOut from "./NavLogOut";
import NavProfile from "./NavProfile";
import NavFilter from "./NavFilter";

export default function NavSigned() {
  return (
    <NavBar>
      <NavLogo />
      <NavTitle />
      <NavNewEvent />
      <NavFilter/>
      <NavProfile />
      <NavLogOut />
    </NavBar>
  );
};
