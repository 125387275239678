import MainLayout from "../layouts/MainLayout";
// import { useSearchParams } from "react-router-dom";
// import { ComponentDispatcher } from "../components/common/ComponentDispatcher";
// import LoginOptions from "features/auth/LoginOptions.React";
import LoginEmail from "features/auth/LoginEmail.React";
// import LoginFb from "features/auth/LoginFb.React";

// const loginForms: ComponentDispatcher = {
//   email: <LoginEmail />,
//   fb: <LoginFb />,
//   "": <LoginOptions />,
// };

// function useLoginType() {
//   const [searchParams] = useSearchParams();
//   const loginType = searchParams.has("type")
//     ? searchParams.get("type") ?? ""
//     : "";
//   const mainComponent =
//     loginType in loginForms ? loginForms[loginType] : <LoginOptions />;
//   return mainComponent;
// }

export function LoginPage() {
  // const loginComponent = useLoginType();
  return <MainLayout><LoginEmail /></MainLayout>;
}
