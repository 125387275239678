import { EventAdapter } from "models/Event";
import {
  CardBox,
  CategoryIcon,
  CategoryName,
  CountAndUnit,
  DateTimeInfo,
  EventName,
  TimeElapsed,
} from "./CardComponents";
import { getCategoryByID } from "models/Category";

export const DefaultEventCard = ({
  event,
  isTodayEvent,
  displayCountAndUnit = true,
}: {
  event: EventAdapter;
  isTodayEvent: boolean;
  displayCountAndUnit: boolean;
}) => {
  const categoryName = getCategoryByID(event.category_id);
  return (
    <CardBox>
      <div className="flex items-center">
        <CategoryIcon categoryName={categoryName} />
        <label className="ml-2 flex-1">
          <DateTimeInfo createdDate={event.created_date} eventId={event.id} />
          <CategoryName categoryName={categoryName} />
        </label>
        {displayCountAndUnit && (
          <label className="ml-2">
            <CountAndUnit
              count={event.price}
              unitName={event.unit_name}
            />
          </label>
        )}
      </div>
      {event.name && <EventName name={event.name} />}
      {isTodayEvent && <TimeElapsed elapsedTime={event.getElapsedTime()} />}
    </CardBox>
  );
};
