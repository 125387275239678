import { FormCount, FormField, FormLabel } from "components/Form";
import { useTranslation } from "react-i18next";
import { EditEventCardProps } from "../Utils";

export type LabeledCountCardFormProps = {
  titleKey: string;
  stepPrecision: "0.1" | "1";
  includeUnitName: boolean;
  dataList?: Array<number>;
} & EditEventCardProps;

export const LabeledCountCardForm = ({
  event,
  onValueChange,
  titleKey,
  stepPrecision,
  includeUnitName,
  dataList,
}: LabeledCountCardFormProps) => {
  const { t } = useTranslation();
  return (
    <>
    <FormField>
      <span className="flex-none w-16 p-2 dark:text-white">{t(titleKey)}</span>
      <div className="flex-auto flex">
        <FormCount
          name="price"
          step={stepPrecision}
          value={event?.price}
          onChange={onValueChange}
          dataList={dataList}
        />
        {includeUnitName && <span className="basis-1/4 p-2 dark:text-white">{event.unit_name}</span>}
      </div>
    </FormField>
    </>
  );
};
