import { retry } from "@reduxjs/toolkit/query/react";
import { RegisterUser, User, ActivateAccount, ResetPassword } from "models/models";
import { api } from "./api";
import qs from "qs";
import { ChangeResult } from "./utils";

export type LoginResponse = {
  message: string;
  user: User | null;
};

const authApiTagged = api.enhanceEndpoints({ addTagTypes: ["User"] });
export const authApi = authApiTagged.injectEndpoints({
  endpoints: (build) => ({
    login: build.mutation<LoginResponse, any>({
      query: (credentials: any) => ({
        url: "api/auth/login",
        method: "POST",
        headers: { "Content-type": "application/x-www-form-urlencoded" },
        body: qs.stringify(credentials),
      }),
      extraOptions: {
        backoff: () => {
          // We intentionally error once on login, and this breaks out of retrying. The next login attempt will succeed.
          retry.fail({ fake: "error" });
        },
      },
      invalidatesTags: ["User"],
    }),
    register: build.mutation<ChangeResult, any>({
      query: (credentials: RegisterUser) => ({
        url: "api/auth/register",
        method: "POST",
        headers: { "content-type": "application/x-www-form-urlencoded" },
        body: qs.stringify(credentials),
      }),
    }),
    activate: build.mutation<ChangeResult, any>({
      query: (data: ActivateAccount) => ({
        url: "api/auth/activate",
        method: "POST",
        headers: { "content-type": "application/x-www-form-urlencoded" },
        body: qs.stringify(data),
      }),
      extraOptions: {
        backoff: () => {
          // We intentionally error once on login, and this breaks out of retrying. The next login attempt will succeed.
          retry.fail({ fake: "error" });
        },
      },
    }),
    reset: build.mutation<ChangeResult, any>({
      query: (data: ResetPassword) => ({
        url: "api/auth/reset",
        method: "POST",
        headers: { "content-type": "application/x-www-form-urlencoded" },
        body: qs.stringify(data),
      }),
      extraOptions: {
        backoff: () => {
          // We intentionally error once on login, and this breaks out of retrying. The next login attempt will succeed.
          retry.fail({ fake: "error" });
        },
      },
    }),
    logout: build.mutation<{}, any>({
      query: () => ({
        url: "api/auth/logout",
        method: "POST",
      }),
      invalidatesTags: ["User"],
    }),
  }),
});

export const {
  useLoginMutation,
  useRegisterMutation,
  useLogoutMutation,
  useResetMutation,
  useActivateMutation,
} = authApi;

export const {
  endpoints: { login, logout, register, activate },
} = authApi;
