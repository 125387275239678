import NavSigned from "./NavSigned";
import NavUnsigned from "./NavUnsigned";
import { useGetUserQuery } from "services/user";

export const NavComponent = () => {
  const { data: user } = useGetUserQuery();
  if (user) {
    return <NavSigned />;
  } else {
    return <NavUnsigned />;
  }
};
