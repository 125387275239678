import { useRef, useState } from "react";
import { useResetMutation } from "services/auth";
import CenterContainer from "components/common/CenterContainer";
import ItemCard from "components/common/ItemCard";
import { FormInput } from "./FormInput";
import FormButtonsBar from "./FormButtonsBar";
import { ButtonPrimary } from "components/common/Buttons";
import { useTranslation } from "react-i18next";
import { translations } from "locales/translations";
import { FormHeader } from "./FormHeader";
import "./ReCaptcha.css";
import { ErrorToast, SuccessToast, useToast } from "components/Toast";

const RegisterCardForm = ({ onSuccess }: { onSuccess: () => void }) => {
  const { t, i18n } = useTranslation();
  const emailRef = useRef<HTMLInputElement>(null);
  const [reset, { isError }] = useResetMutation();
  const [successToast, setSuccessToast] = useToast();
  const [errorToast, setErrorToast] = useToast();

  const onResetPassword = async (email: string) => {
    const result = await reset({
      email,
      locale: i18n.language,
    }).unwrap();

    if (!result.result || isError) {
      if (result.message) {
        setErrorToast({
          visible: true,
          text: t(translations.register.errors[result.message]),
        });
      } else {
        setErrorToast({
          visible: true,
          text: t(translations.register.incorrectCredentials),
        });
      }
    } else {
      setSuccessToast({
        visible: true,
      });
      onSuccess();
    }
  };
  const onSubmit = async (evt?: React.FormEvent<HTMLFormElement>) => {
    if (evt !== undefined && evt.preventDefault) {
      evt.preventDefault();
    }
    if (!emailRef?.current?.value) {
      setErrorToast({
        visible: true,
        text: t(translations.register.passwordsMismatchError),
      });
      return;
    }
    await onResetPassword(emailRef.current.value);
  };

  return (
    <form onSubmit={onSubmit}>
      <SuccessToast visible={successToast.visible}>
        {t(translations.resetPassword.successfulResetMessage)}
      </SuccessToast>
      <ErrorToast visible={errorToast.visible}>{errorToast.text}</ErrorToast>
      <FormHeader>{t(translations.resetPassword.header)}</FormHeader>
      <FormInput
        name="email"
        type="email"
        placeholder="sample@website.com"
        ref={emailRef}
        title={t(translations.resetPassword.emailAddress)}
      />

      <div className="mt-4">
        <FormButtonsBar>
          <ButtonPrimary type="submit">
            {t(translations.resetPassword.confirmButton)}
          </ButtonPrimary>
        </FormButtonsBar>
      </div>
    </form>
  );
};

const ResetPasswordSendInformation = () => {
  const { t } = useTranslation();
  return (
    <>
      <h1 className="text-lg uppercase mt-4 mb-8 font-semibold">
        {t(translations.resetPassword.linkSendInformationHeader)}
      </h1>
      <p>{t(translations.resetPassword.linkSendInformation)}</p>
    </>
  );
};

export function ResetPassword() {
  const [success, setSuccess] = useState<boolean>(false);
  return (
    <CenterContainer>
      <ItemCard>
        {success ? (
          <ResetPasswordSendInformation />
        ) : (
          <RegisterCardForm onSuccess={() => setSuccess(true)} />
        )}
      </ItemCard>
    </CenterContainer>
  );
}
