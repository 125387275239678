import { useState } from "react";
import { useActivateMutation } from "services/auth";
import { useSearchParams } from "react-router-dom";

import {
  ErrorToast,
  InfoToast,
  LoadingToast,
  useToast,
} from "components/Toast";
import { redirectHome } from "utils/nav";
import { ButtonPrimary } from "components/common/Buttons";
import CenterContainer from "components/common/CenterContainer";
import { FormHeader } from "./FormHeader";
import FormButtonsBar from "./FormButtonsBar";
import { useTranslation } from "react-i18next";
import { translations } from "locales/translations";

export const ActivateAccountComponent = () => {
  const { t } = useTranslation();
  const [activate, { isError, error, isLoading }] = useActivateMutation();
  const [searchParams] = useSearchParams();
  const [errorToast, setErrorToast] = useToast();
  const [successToast, setSuccessToast] = useToast();
  const [activateButtonVisible, setActivateButtonVisible] = useState(true);

  const doActivate = async () => {
    if (!(searchParams.has("email") || searchParams.has("activation_code"))) {
      setErrorToast({
        visible: true,
        text: t(translations.activateAccount.errorIncorrectParams),
      });
      return;
    }
    setActivateButtonVisible(false);

    const result = await activate({
      email: searchParams.get("email"),
      activationCode: searchParams.get("activation_code"),
    }).unwrap();
    if (result.result) {
      if (result && result.result) {
        setSuccessToast({ visible: true });
      } else {
        setErrorToast({
          visible: true,
          text: t(translations.activateAccount.errorUndefined),
        });
      }
    }
  };

  return (
    <CenterContainer>
      <ErrorToast visible={isError || errorToast.visible}>
        {error?.toString() || errorToast.text}
      </ErrorToast>
      <InfoToast visible={successToast.visible} onClose={redirectHome}>
        {t(translations.activateAccount.accountActivatedMessage)}
      </InfoToast>
      <LoadingToast visible={isLoading}>
        {t(translations.activateAccount.accountActivatingMessage)}
      </LoadingToast>
      {activateButtonVisible ? (
        <div>
          <FormHeader>
            {t(translations.activateAccount.accountActivationLabel)}
          </FormHeader>
          <FormButtonsBar>
            <ButtonPrimary onClick={doActivate}>
              {t(translations.activateAccount.activationButton)}
            </ButtonPrimary>
          </FormButtonsBar>
        </div>
      ) : null}
    </CenterContainer>
  );
};
