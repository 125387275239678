import { formatDateStr, getDateNow } from "utils/date";

export type Product = {
  id: number;
  category_id: number;
  category_name: string;
  name: string;
  description: string;
  price: number;
  created_date: string;
};
export type Category = {
  id: number;
  name: string;
};
export type Gender = "Male" | "Female";
export type User = {
  id: number;
  email: string;
  created_at: string;
  name: string;
  gender: string;
  birth_date: string;
};
export type RegisterUser = {
  email: string;
  password: string;
  password_confirmation: string;
  token: string;
  locale: string;
};
export type ActivateAccount = {
  email: string;
  activationCode: string;
};
export type ResetPassword = {
  email: string;
};
export function getAllGenderOptions(): Array<Gender> {
  return ["Male", "Female"];
}
export function createProduct() {
  return {
    id: -1,
    category_id: 1,
    category_name: "",
    name: "",
    description: "",
    price: 100.0,
    created_date: formatDateStr(getDateNow()),
  };
}
