import { ButtonPrimary } from "./common/Buttons";
import { translations } from "locales/translations";
import { useTranslation } from "react-i18next";
import { useGetUserQuery } from "services/user";
import { useNavigate } from "react-router-dom";

const LoginBox = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  return (
    <div className="flex flex-col items-center m-4">
      <ButtonPrimary onClick={() => navigate("/login")} className="m-2">
        {t(translations.welcomePage.logIn)}
      </ButtonPrimary>
    </div>
  );
};

export const WelcomeComponent = () => {
  const { data: user, isLoading } = useGetUserQuery();
  const { t } = useTranslation();
  return (
    <div className="flex flex-col items-center">
      <img src="/Logo-512.png" alt="" className="w-52 pb-16 transition ease-in-out delay-150 active:scale-110" />
      <h1 className="text-xl font-bold text-center">
        {t(translations.welcomePage.header)}
      </h1>
      {/* <p className="m-8">{t(translations.welcomePage.subHeader)}</p> */}
      <p className="m-8">{t(translations.welcomePage.description)}</p>
      {user || isLoading? <></> : <LoginBox />}
    </div>
  );
};
