import { ResetPassword } from "features/auth/ResetPassword.React";
import MainLayout from "layouts/MainLayout";

export const ResetPasswordPage = () => {
  return (
    <MainLayout>
      <ResetPassword />
    </MainLayout>
  );
};
